<template>
	<shutter-panel ref="shutterPanel" />
</template>

<script type="text/javascript">
import Shutter from "@/mixins/Shutter.js"
import ShutterHorse from "@/mixins/shutters-manager/Horse.js"
import Vue from 'vue'

export default {
	name: 'SearchWeatherbys',
	mixins: [Shutter, ShutterHorse],
	data () {
		return {
			mask_weatherbys: false
		}
	},

	mounted() {
		this.init_component()
	},

	methods: {
		init_component() {
			this.mask_weatherbys = this.getConfigWeatherbys()

			if(this.mask_weatherbys) {
				this.setupAjoutManuel()
			}
			else {
				this.setupSearchWeatherbys()
			}
		}
	}
};

</script>